<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('unihall_quotas_title')" :isColumns="true" :isNewButton="true"
                    @new-button-click="openForm('add')" @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                :unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('unihall_quotas_title')" :isColumns="true" :isNewButton="true"
                    @new-button-click="openForm('add')" @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                :unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
                <b-row>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('name')">
                            <b-form-input v-model="datatable.queryParams.filter.name" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('quota')">
                            <b-form-input v-model="datatable.queryParams.filter.quota" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('academic_year')">
                            <academic-years-selectbox v-model="datatable.queryParams.filter.academic_year">
                            </academic-years-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('gender')">
                            <gender-selectbox v-model="datatable.queryParams.filter.gender"></gender-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('country')">
                            <b-form-select :options="[{ text: $t(`unihall_country_code.TR`), value: 'TR' }, { text: $t(`unihall_country_code.YU`), value: 'YU' }]"
                                v-model="datatable.queryParams.filter.nationality_type"></b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                :total="datatable.total" :queryParams="datatable.queryParams" @on-page-change="onPageChange"
                @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange" v-show="datatable.showTable">
            </datatable>

            <CommonModal ref="formModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ formModalTitle.toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <DataForm :formId="formId" :formType="formType" v-model="formData" :disabled="formDisabled"
                        @close="formClear(true)" />
                </template>
            </CommonModal>
            <CommonModal ref="priceFormModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('price_add') }}
                </template>
                <template v-slot:CommonModalContent>
                    <PriceDataForm :formId="priceFormId" :formType="priceFormType" v-model="priceFormData" :disabled="priceFormDisabled"
                        @close="priceFormClear(true)" />
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from '@/layouts/AppLayout';
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'

// Component
import DatatableFilter from '@/components/datatable/DatatableFilter';
import Datatable from '@/components/datatable/Datatable';
import SemestersSelectbox from '@/components/interactive-fields/SemestersSelectbox'

// Services
import UnihallQuotaService from '@/services/UnihallQuotaService';

// Other
import qs from 'qs';

export default {
    name: "UnihallQuotas",
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        SemestersSelectbox,
        DataForm: () => import("./DataForm"),
        PriceDataForm: () => import("./PriceDataForm")
    },
    metaInfo() {
        return {
            title: this.$t('unihall_quotas_title')
        };
    },
    data() {
        return {
            formId: null,
            formType: "",
            formData: {},
            formDisabled: false,
            priceFormDisabled:false,
            priceFormId:null,
            priceFormData:{},
            priceFormType:"",
            reject: {
                undergraduate_transfer_id: null,
                explanation: null,
            },
            delete: {
                undergraduate_transfer_id: null,
            },
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('show'),
                                class: 'ri-search-eye-line',
                                hidden: true,
                                callback: (row) => {
                                    this.openForm('show', row);
                                }
                            },
                            {
                                text: this.$t('edit'),
                                class: 'ri-search-eye-line',
                                hidden: true,
                                callback: (row) => {
                                    this.openForm('update', row);
                                }
                            },
                            {
                                text: this.$t('price_edit'),
                                class: 'ri-search-eye-line',
                                hidden: true,
                                callback: (row) => {
                                    this.$router.push('/unihall/price/' + row.id)
                                }
                            },
                            {
                                text: this.$t("delete"),
                                class: "ri-delete-bin-2-line",
                                permission: 'undergraduatetransfer_show',
                                callback: (row) => {
                                    this.openForm('delete', row);
                                },
                            },
                        ]
                    },
                    {
                        label: this.$t('id'),
                        field: 'id',
                        sortable: true,
                        hidden: true
                    },
                    {
                        label: this.$t('name'),
                        field: 'name',
                        sortable: false,
                        hidden: false,
                    },
                    {
                        label: this.$t('quota'),
                        field: 'quota',
                        sortable: false,
                        hidden: false,
                    },
                    {
                        label: this.$t('remaining_quota'),
                        field: 'remaining_quota',
                        sortable: false,
                        hidden: false,
                    },
                    {
                        label: this.$t('country'),
                        field: 'nationality_type',
                        sortable: false,
                        hidden: false,
                        formatFn: (value, row) => {
                            return this.$t(`unihall_country_code.${row.nationality_type}`);
                        },
                    },
                    {
                        label: this.$t('academic_year'),
                        field: 'academic_year',
                        sortable: false,
                        hidden: false,
                    },
                    {
                        label: this.$t('gender'),
                        field: 'gender',
                        sortable: false,
                        hidden: false,
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: 'id',
                    page: 1,
                    limit: 20
                }
            }
        }
    },
    computed: {
        formModalTitle() {
            if (this.formType == "add") {
                return `${this.$t('room')} ${this.$t('add')}`
            }
            return ""
        }
    },
    methods: {
        async getData() {
            const config = {
                params: {
                    filter: {

                    },
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };

            const response = await UnihallQuotaService.getAll(config)

            if (response.data.success && response.data.data.length > 0) {
                this.formData = response.data.data[0]

                const index = this.datatable.rows.findIndex(item => item.id === this.formId)
                if (index > -1) {
                    this.datatable.rows[index] = response.data.data[0]
                }
            }
        },
        openPriceForm(str, row = null){
            if (str === "show") {
                this.priceFormDisabled = true
            }
            if (row) {
                this.priceFormId = row.id
                // this.priceFormData = { ...row }

            }
            this.priceFormType = str
            this.$refs.priceFormModal.$refs.commonModal.show()
        },
        openForm(str, row = null) {
            if (str === "show" || str === "delete") {
                this.formDisabled = true
            }
            if (row) {
                this.formId = row.id
                this.formData = { ...row }

            }
            this.formType = str
            this.$refs.formModal.$refs.commonModal.show()
        },
        filter() {
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };

            return UnihallQuotaService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total

                    if (this.formId) {
                        const row = this.datatable.rows.find(item => item.id === this.formId)
                        this.formData = row
                    }
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        // Form
        formClear(close = false) {
            if (close) {
                this.$refs.formModal.$refs.commonModal.hide()
            }
            this.formId = null
            this.formType = ""
            this.formData = {}
            this.formDisabled = false
            this.getRows();
        },
        priceFormClear(close = false) {
            if (close) {
                this.$refs.priceFormModal.$refs.commonModal.hide()
            }
            this.priceFormId = null
            this.priceFormType = ""
            this.priceFormData = {}
            this.priceFormDisabled = false
            this.getRows();
        },

    },
    mounted(){
        this.getRows()
    }
}
</script>
